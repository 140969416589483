import {
    Hero, Expander, ExpanderItem, SEO, Modal,
} from '@brandedholdings/react-components';
import { Log } from '@brandedholdings/react-components/build/utils';
import PropTypes from 'prop-types';
import React from 'react';
import PagePost from '../../models/PagePost';
import { mediaNet as pageMeta } from '../../data/page_meta.yml';
import { siteMeta } from '../../data/site_meta.yml';
import PageWrapper from '../../components/DefaultPageWrapper';
import SearchPills from '../../components/SearchPills';
import ResultsForm from '../../components/ResultsForm';
import OfferForm from '../../components/OfferForm';

class InterlincxResults extends React.Component {
    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.state = {
            modalIsOpen: false,
            ready: false,
        };
    }

    getChildContext() {
        return { siteMeta, pageMeta };
    }

    componentDidMount = () => {
        setTimeout(() => this.setState({ ready: true }), 1000);
    };

    render() {
        const page = this.props;
        pageMeta.link = page.location.pathname;
        const post = new PagePost(pageMeta, siteMeta);
        const { template } = pageMeta;
        const { modalIsOpen, ready } = this.state;

        Log.toDataLayer({
            event: 'search',
        });

        return (
            <PageWrapper location={this.props.location} templateType={template} adserve>
                <SEO post={post} />
                <Hero
                    heading="Great News!"
                    subheading="Personal loan options are available!"
                    actionText={null}
                    actionLink="#"
                    heroModifiers="hero--empty"
                    actionModifiers={null}
                />
                <div className="filter">
                    {ready && (
                        <div className="layout layout--2-column">
                            <div className="layout-content pills">
                                <small>Filters currently applied:</small>
                                <SearchPills />
                            </div>
                            <div className="sidebar">
                                <Expander altStyle={false}>
                                    <ExpanderItem title="Filter Results">
                                        <OfferForm interlincxSearch onSubmit={this.onFormSubmit} />
                                    </ExpanderItem>
                                </Expander>
                            </div>
                            <div className="layout-content">
                                <div id="results-container" />
                            </div>
                        </div>
                    )}
                </div>
                <Modal
                    isModalOpen={modalIsOpen}
                    heading={false}
                    triggerOnClick
                    className="form-modal"
                    onClose={this.onModalFormSubmit}
                    ref={this.modalRef}
                >
                    <ResultsForm onSubmit={this.onModalFormSubmit} onCloseModal={this.onModalFormSubmit} />
                </Modal>
            </PageWrapper>
        );
    }
}

InterlincxResults.childContextTypes = {
    siteMeta: PropTypes.object,
    pageMeta: PropTypes.object,
};

export default InterlincxResults;
