import React from 'react';
import { connect } from 'react-redux';
import { Log } from '@brandedholdings/react-components/build/utils';
import './pill.scss';
import Modal from '@brandedholdings/react-components/build/Modal';
import { Form } from '@brandedholdings/react-components';

class SearchPills extends React.Component {
    constructor(props) {
        super(props);
        this.props = props;
        if (!this.props.fields['creditRating.label']) {
            this.props.fields['creditRating.label'] = 'Poor (less than 620)';
        }
        this.state = {
            loading: false,
        };
    }

    getPills() {
        let pills = {};
        let possiblePills = {
            "businessOwner": "Business Owner",
            "creditRating.label": "Credit",
            "homeAddress.zip": "Zip",
            "military": "Military",
            "residenceStatus.label": "Residence Status",
            "totalDebt.label": "Total Debt",
            "vehiclePaidOff.value": "Vehicle Paid Off",

        };

        for (var property in this.props.fields) {
            if (Object.keys(possiblePills).includes(property)){
                if (this.props.fields[property] === '') continue;
                if (typeof this["format" + property.replace('.', '')] === 'function') {
                    pills[property] = this["format" + property.replace('.', '')](property, this.props.fields[property]);
                } else {
                    pills[property] = (possiblePills[property] + ': ' + this.props.fields[property]);
                }
            }
        }

        return pills;
    }

    formatmilitary(key, value) {
        return (parseInt(value) == 1 || value === true) ? 'Military' : 'Not Military';
    }

    formatbusinessOwner(key, value) {
        return (parseInt(value) == 1) ? "Business Owner" : "Not Business Owner";
    }


    formatresidenceStatuslabel(key, value) {
        return value + " Home";
    }

    formattotalDebtlabel(key, value) {
        return (value == 'Yes' ? 'More' : 'Less') + " than $10,000 in Debt";
    }

    formatvehiclePaidOffvalue(key, value) {
        switch (value) {
            case "VEHICLE_PAID_OFF_YES":
                return "Vehicle paid off";
            case "VEHICLE_PAID_OFF_NO":
                return "Vehicle not paid off";
        }
    }

    getBase(key) {
        let base = key
        if (key.match(/.+\..+/)) {
            base = key.split('.')[0];
        }
        return base;
    }

    showLoading = () => {
        this.setState({
            loading: true,
        });
        setTimeout(() => {
            this.setState({
                loading: false,
            });
            console.log(this.state.loading);
        }, 300);
    }

    removePill(key, e) {
        e.preventDefault();
        let base = this.getBase(key);
        for (var property in this.props.fields) {
            if (property.includes(base)) {
                this.props.interlincxConfig.params[property] = '';
            }
        }

        this.props.fields[key] = '';

        this.updateState();
        this.updateResults();
    }

    updateResults= () => {
        if (_.isEqual(this.props.interlincxConfig, {})) return;
        this.showLoading();
        let target = document.getElementById('results-container');
        target.innerHTML = '';
        const config = Object.assign(
            {},
            this.props.interlincxConfig.params,
            { zoneId: this.props.interlincxConfig.zoneMappings.primary, displayLocation: 'fm-offerwall' }
        );
        window.adverse(target, config);
    }

    updateState = () => {
        let newState = Object.assign({}, this.props.fields);
        let interlincxConfig = Object.assign({}, this.props.interlincxConfig);
        this.setState({
            applicantFields: newState,
            interlincxConfig: interlincxConfig,
        });

        this.props.dispatch({
            type: 'SET_APPLICANT_FIELDS',
            applicantFields: newState,
        });

        this.props.dispatch({
            type: 'SET_INTERLINCX_CONFIG',
            interlincxConfig: this.props.interlincxConfig,
        });
    }

    render() {
        let pills = this.getPills();
        return (
            <div>
                {Object.keys(pills).map((key) => {
                    return (
                        <span className="nav-pill" id={"pill-"+key}>
                            {pills[key]}
                            <a className="remove-pill" onClick={ (e) => this.removePill(key, e) }>x</a>
                        </span>
                    );
                })}
            </div>
        );
    }
}


function mapStateToProps(state) {
    return {
        fields: state.applicantFields,
        fieldErrors: state.fieldErrors,
        interlincxConfig: state.interlincxConfig,
    };
}

export default connect(mapStateToProps)(SearchPills);
